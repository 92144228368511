<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Gestion des objectifs</h4>
        <DataTable
          :value="clients"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'nom',
            'prenom',
            'email',
            'type',
            'telephone',
            'gsm',
          ]"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucun Utilisateur trouvé. </template>
          <template #loading>
            Chargement des données Utilisateurs. Veuillez patienter.
          </template>
          <Column
            field="nom"
            header="Nom"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.nom }}
            </template>
          </Column>
          <Column
            field="prenom"
            header="Prénom"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.prenom }}
            </template>
          </Column>
          <Column
            field="email"
            header="Email"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.email }}
            </template>
          </Column>
          <Column
            field="type"
            header="Type "
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <Column
            field="telephone"
            header="Téléphone"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.tele }}
            </template>
          </Column>
          <Column
            field="gsm"
            header="Gsm"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.gsm }}
            </template>
          </Column>
          <Column header="Actions" style="min-width: 12rem">
            <template #body="data">
              <span class="p-buttonset">
                <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                />
              </span>
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="dialogOfShow"
          :style="{ width: '450px' }"
          header="Détails du Utilisateur"
          :modal="true"
          class="p-fluid"
        >
          Nom : {{ clientToShow.nom }}
          <Divider />
          Prénom :{{ clientToShow.prenom }}
          <Divider />
          Email :{{ clientToShow.email }}
          <Divider />
          Type :{{ clientToShow.name }}
          <Divider />
          Téléphone :{{ clientToShow.tele }}
          <Divider />
          Email :{{ clientToShow.email }}
          <Divider />
          Gsm :{{ clientToShow.gsm }}
          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfShow = false"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      Permissions: { update: false, delete: false, add: false, show: false },
      clients: [],
      filters1: null,
      loading1: true,
      display: false,
      dialogOfShow: false,
      clientToShow: {},

      id: null,
    };
  },
  mounted() {
    console.log(localStorage.permissionNames);
    this.Permissions.update =
      localStorage.permissionNames.includes("Modifier objectif");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Supprimer objectif");
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter objectif");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste objectif");
    if (this.Permissions.show) {
      this.showAllclients();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    this.initFilters1();
  },
  methods: {
    showclientById(id) {
      this.$router.push({ name: "userobjectifs", params: { id: id } });
    },
    showAllclients() {
      axios
        .get("showUsers")
        .then((response) => {
          console.log(response);
          //   this.clients = response.data.users;
          this.clients = response.data.users.filter((obj) => {
            return obj.name != "Administrateur";
          });
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
@import "../../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>